import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import EntitySharingLinks from "components/Sharing/EntitySharingLinks";

import { getBaseUrl } from "constants/base";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import getUserUrl from "utils/entity/getUserUrl";
import { getCacheBustingSuffix, getTop8ImageUrl } from "utils/entity/user";

import useLoggedInUser from "hooks/useLoggedInUser";

import colours from "styles/colours";

const UserSharingLinks = (props) => {
  const {
    user,
    isTop8,
    title: passedTitle,
    eventProps: passedEventProps,
  } = props;

  const loggedInUser = useLoggedInUser();
  const isLoggedInUser =
    loggedInUser && user.get("id") === loggedInUser.get("id");
  const downloadUrl = `${getTop8ImageUrl(user)}?download=true`;
  let title =
    passedTitle ||
    `${
      isLoggedInUser ? "My" : `${getUserDisplayName(user)}'s`
    } activity on Podchaser: `;
  let twitterTitle = title;

  if (isTop8) {
    title =
      passedTitle ||
      `${
        isLoggedInUser ? "my" : `${getUserDisplayName(user)}'s`
      } Top 8 Podcasts`;
    twitterTitle = `Here are ${
      isLoggedInUser ? "my" : `${getUserDisplayName(user)}'s`
    } #Top8Podcasts - what are yours? Make your own Top 8 with @Podchaser!`;
  }

  const { info, eventProps } = useMemo(
    () => ({
      info: {
        url: `${getBaseUrl()}${getUserUrl(user)}?t=${getCacheBustingSuffix(
          user
        )}`,
        title,
        twitterTitle,
      },
      eventProps: {
        username: user.get("username"),
        ...passedEventProps,
      },
    }),
    [user, title, twitterTitle, passedEventProps]
  );

  const downloadTop8Image = useCallback(() => {
    window.open(downloadUrl);
  }, [downloadUrl]);

  return (
    <EntitySharingLinks
      {...props}
      info={info}
      eventProps={eventProps}
      extraShareItems={[
        isTop8 && {
          title: "Download Top 8 Image",
          action: downloadTop8Image,
          icon: faDownload,
          color: colours.oldSecondary,
          key: "downloadTop8",
        },
      ].filter((i) => !!i)}
      description={
        isTop8 &&
        "When you share your User Profile, your top 8 podcasts will appear as a featured image"
      }
    />
  );
};

UserSharingLinks.propTypes = {
  user: PropTypes.instanceOf(Map),
  urlParam: PropTypes.string,
  title: PropTypes.string,
  positionFixed: PropTypes.bool,
  isTop8: PropTypes.bool,
  loading: PropTypes.bool,
  variation: PropTypes.string,
  fontSize: PropTypes.string,
  eventProps: PropTypes.object,
};
UserSharingLinks.defaultProps = {
  user: Map({}),
  urlParam: "",
  title: null,
  positionFixed: false,
  isTop8: false,
  loading: false,
  variation: undefined,
  fontSize: undefined,
  eventProps: {},
};

export default memo(UserSharingLinks);
